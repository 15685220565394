import { ref, computed, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePedidos() {
  const { route, router } = useRouter()

  const toast = useToast()

  const inquilinos = computed(() => [])

  // const expedienteSelect = ref(null)
  const catastroSelect = ref(null)
  const usersInmueblesSelect = ref([])

  const ccaa = computed(() => route.value.params.ccaa)
  const expedienteSelect = computed(() => route.value.params.expediente)
  const provincias = computed(() => store.state.expedientes.provincias)
  const ca = computed(() => store.state.expedientes.ca)
  const expedientes = computed(() => store.state.expedientes.expedientes)
  const catastro = computed(() => store.state.expedientes.catastro)
  const catastroUsers = computed(() => (catastro.value ? catastro.value.catastroUsers : null))
  const inmuebles = computed(() => (catastro.value ? catastro.value.inmuebles : null))
  const users = computed(() => store.state.expedientes.users)
  const expedientesCCAA = computed(() => {
    if (!expedientes.value.length || !ccaa.value) return []
    return expedientes.value.filter(e => e.status.toLowerCase() !== 'baja' && e.ca.code === ccaa.value)
  })

  const fetchCA = () => store.dispatch('expedientes/setCA')
  const fetchProvincias = () => store.dispatch('expedientes/setProvincias')

  const fetchBasicData = () => {
    fetchCA()
    fetchProvincias()
  }

  const fetchExpedientes = () => {
    // console.log(provincias.value)
    store.dispatch('expedientes/setExpedientes')
  }

  const proviciaIdToName = id => provincias.value.filter(p => p.provinciaId === id).map(n => n.provinciaName)[0]

  const getUsersDb = () => {
    // console.log(usersInmueblesSelect.value)
    usersInmueblesSelect.value.forEach(item => {
      // console.log(`getUser(${item.id})`)
      store.dispatch('expedientes/getUser', item.id)
    })
  }

  const setUsersInmueblesSelect = () => {
    // const { catastroUsers } = catastro.value
    if (!catastroUsers.value) {
      // console.log('No existen usuarios')
      toast({
        component: ToastificationContent,
        props: {
          title: 'No existen datos',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      },
      {
        position: 'top-center',
      })
      return
    }
    const keys = Object.keys(catastroUsers.value)
    const usersIdList = []
    for (let index = 0; index < keys.length; index += 1) {
      const key = keys[index]
      const element = catastroUsers.value[key]
      element.forEach(item => {
        const userInmueble = item
        userInmueble.inmuebleId = key
        usersIdList.push(userInmueble)
      })
    }
    // console.log(JSON.stringify(usersIdList))
    usersInmueblesSelect.value = usersIdList
    getUsersDb()
  }

  const fechaCalc = (dia, mes, year) => {
    if (!dia || !mes || !year) {
      return {
        dia: '',
        mes: '',
        year: '',
        string: ' - ',
        fecha: false,
      }
    }
    const fecha = new Date(`${year}-${mes}-${dia}`)
    return {
      dia: fecha.getDate(),
      mes: fecha.getMonth() + 1,
      year: fecha.getFullYear(),
      string: fecha.toLocaleDateString(),
      fecha,
    }
  }

  const calculaEdad = fechaNacimiento => {
    if (!fechaNacimiento) {
      return 0
    }
    const hoy = new Date()
    const cumpleanos = fechaNacimiento
    let edad = hoy.getFullYear() - cumpleanos.getFullYear()
    const m = hoy.getMonth() - cumpleanos.getMonth()

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad -= 1
    }

    return edad
  }

  watch(catastro, () => {
    // console.log('catastro ha cambiado')
    setUsersInmueblesSelect()
  })

  const selectedExpediente = id => {
    router.push({ name: 'expedientes-printer-expediente', params: { expediente: id } })
  }

  const getExpediente = id => {
    const expediente = expedientes.value.filter(e => e.id === id)[0]
    catastroSelect.value = expediente.refCatastro
    store.dispatch('expedientes/getCatastro', expediente.refCatastro)
  }

  const usuariosInmuebles = computed(() => {
    if (!catastroUsers.value) return []
    const keysUsers = Object.keys(catastroUsers.value)
    const list = []
    keysUsers.forEach(key => {
      const usersInmueble = catastroUsers.value[key]
      const inmueble = inmuebles.value[key]
      const usuarios = []
      usersInmueble.forEach(user => {
        const dataUser = users.value.filter(u => u.id === user.id)[0]
        const priv = dataUser && dataUser.private ? dataUser.private : false
        const admin = dataUser && dataUser.admin ? dataUser.admin : false
        const nif = priv ? priv.nif : ''
        const nombre = priv ? priv.nombre : ''
        const apellido1 = priv ? ` ${priv.apellido1}` : ''
        const apellido2 = priv ? ` ${priv.apellido2}` : ''
        const nombreApellidos = `${nombre}${apellido1}${apellido2}`
        const tlf1 = priv ? priv.tlf1 : ''
        const email = admin ? admin.email : ''

        usuarios.push({
          empadronado: user.empadronado,
          id: user.id,
          nombreApellidos,
          nombre,
          nif,
          tipo: user.tipo || '1',
          cuota: user.cuota,
          email,
          tlf1,
          invita: false,
          refCat: inmueble.refCat,
        })
      })
      inmueble.participa = inmueble.participa ? inmueble.participa : false
      list.push({
        key,
        inmueble,
        usuarios,
      })
    })
    return list
  })

  const anexoViviendaMadrid = computed(() => {
    const result = []
    usuariosInmuebles.value.forEach(inmueble => {
      const { usuarios, key } = inmueble
      if (usuarios.length) {
        const dataUsers = []
        let piso = '-'
        let letra = '-'
        usuarios.forEach(user => {
          const data = users.value.filter(u => u.id === user.id)[0]
          if (data) {
            const catastroUserData = catastroUsers.value[key].filter(i => i.id === user.id)[0]
            const empadronado = catastroUserData ? catastroUserData.empadronado : false
            const dirFormulario = data.private.datosFormulario && data.private.datosFormulario.direccion ? data.private.datosFormulario.direccion : false
            const dirPrefer = dirFormulario || data.private.direccion

            if (piso === '-') {
              piso = dirPrefer.piso || '-'
            }
            if (letra === '-') {
              letra = dirPrefer.letra || '-'
            }

            if (data && empadronado) {
              const firma = data.private.datosFormulario && data.private.datosFormulario.firma ? data.private.datosFormulario.firma : ''
              dataUsers.push({
                nif: data.private.nif,
                nombre: data.private.nombre,
                apellido1: data.private.apellido1,
                apellido2: data.private.apellido2,
                firma,
              })
            }
          }
        })
        if (dataUsers.length) {
          result.push({
            dataUsers,
            piso,
            letra,
          })
        }
      }
    })
    return result
  })

  const anexoEdificioMadrid = computed(() => {
    const result = []
    usuariosInmuebles.value.forEach(item => {
      const { usuarios, inmueble } = item
      const dataUser = []
      if (usuarios) {
        usuarios.forEach(user => {
          const data = users.value.filter(u => u.id === user.id)[0]
          if (data) {
            const datosFormulario = data.private.datosFormulario ? data.private.datosFormulario : false
            const firma = datosFormulario && datosFormulario.firma ? datosFormulario.firma : false
            const iprem = datosFormulario ? datosFormulario.pFisica.ipremX3 === '1' : false
            const autoriza = datosFormulario ? datosFormulario.autorizarDatos === '1' : true
            if (firma) {
              dataUser.push({
                data,
                user,
                firma,
                iprem,
                autoriza,
              })
            }
          }
        })
      }

      if (!dataUser.length) {
        if (usuarios) {
          dataUser.push({
            data: users.value.filter(u => u.id === usuarios[0].id)[0],
            user: usuarios[0],
            autoriza: true,
          })
        }
      }

      if (dataUser.length) {
        // console.log(JSON.stringify(dataUser))
        // if (dataUser.length) return // eliminar despues del test
        dataUser.forEach(du => {
          const { data } = du
          const priv = data?.private
          if (data && priv) {
            const fechaNaciemiento = fechaCalc(du.data.private.fechaNacimientoDia, du.data.private.fechaNacimientoMes, du.data.private.fechaNacimientoYear).fecha
            result.push({
              empadronado: du.user.empadronado,
              nombre: du.user.nombreApellidos,
              planta: inmueble.piso,
              letra: inmueble.letra,
              superficie: inmueble.superficie,
              nif: du.user.nif,
              tipo: inmueble.uso === 'Residencial' ? 'vivienda' : 'local',
              uso: du.user.tipo === '0' ? 'propio' : 'alquiler',
              iprem: du.iprem ? du.iprem : false,
              mayor65: fechaNaciemiento ? calculaEdad(fechaNaciemiento) > 65 : false,
              discapacidad: du.data.private.discapacidad && du.data.private.discapacidad.isDiscapacidad,
              noAutoriza: !du.autoriza,
              firma: du.firma ? du.firma : '',
            })
          }
          // else {
          //   console.error('Aquí hay un fallo:')
          //   console.log(JSON.stringify(du))
          //   result.push({
          //     fallo: du,
          //   })
          // }
        })
      }
    })

    // const localesParticipan = this.controlLocales.controlado ? this.controlLocales.valor : this.expediente.detalle.localesParticipan
    const expediente = expedientes.value.filter(e => e.id === expedienteSelect.value)[0]
    const cabecerasAE = {
      direccion: [
        `${expediente.direccion || ''}`,
        `${expediente.cp || '          '} - ${expediente.ca.name || ''}`,
      ],
      localesParticipan: true,
    }
    return {
      result,
      cabecerasAE,
    }
  })

  return {
    expedientes,
    inquilinos,
    ca,
    ccaa,
    expedientesCCAA,
    expedienteSelect,
    usersInmueblesSelect,
    catastro,
    catastroUsers,
    inmuebles,
    users,
    usuariosInmuebles,
    anexoViviendaMadrid,
    anexoEdificioMadrid,
    fetchBasicData,
    fetchExpedientes,
    proviciaIdToName,
    selectedExpediente,
    getExpediente,
  }
}
