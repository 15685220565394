<template>
  <div>
    <b-card>
      <b-card-title>
        Expediente: {{ expedienteSelect }} | {{ anexoEdificioMadrid ? anexoEdificioMadrid.cabecerasAE.direccion[0] : '-' }}
      </b-card-title>

      <b-card-sub-title>
        Inmuebles: {{ usuariosInmuebles ? usuariosInmuebles.length : '-' }}
      </b-card-sub-title>
    </b-card>

    <b-row class="match-height">
      <b-col
        md="6"
        lg="4"
      >
        <b-card>
          <b-card-title>
            Edificio: {{ anexoEdificioMadrid ? anexoEdificioMadrid.result.length : '-' }} Registros
          </b-card-title>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mt-3"
            block
            :disabled="!anexoEdificioMadrid || !anexoEdificioMadrid.result.length"
            @click="imprimirMadEdificio"
          >
            Anexo Edificio
          </b-button>
        </b-card>
      </b-col>

      <b-col
        md="6"
        lg="4"
      >
        <b-card>

          <b-card-title>
            Edificio: {{ anexoViviendaMadrid ? anexoViviendaMadrid.length : '-' }} Registros
          </b-card-title>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mt-3"
            block
            :disabled="!anexoViviendaMadrid.length"
            @click="imprimirMadVivienda"
          >
            Anexo Vivienda
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <!-- <pre>{{ anexoViviendaMadrid }}</pre>
    <pre>{{ anexoEdificioMadrid }}</pre> -->
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { onMounted } from '@vue/composition-api'
import { anexoMadVivienda, anexoMadEdificio } from '@/libs/printer/printer-pdf'
import useExpedientes from './useExpedientes'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      expedienteSelect,
      inquilinos,
      catastro,
      catastroUsers,
      inmuebles,
      users,
      getExpediente,
      usersInmueblesSelect,
      usuariosInmuebles,
      anexoViviendaMadrid,
      anexoEdificioMadrid,
    } = useExpedientes()

    onMounted(() => getExpediente(expedienteSelect.value))

    const imprimirMadEdificio = () => anexoMadEdificio(anexoEdificioMadrid.value.result, anexoEdificioMadrid.value.cabecerasAE)

    const imprimirMadVivienda = () => {
      anexoViviendaMadrid.value.forEach(item => {
        const { dataUsers, piso, letra } = item
        anexoMadVivienda(dataUsers, piso, letra)
      })
    }

    const imprimirMadViviendaTest = () => {
      const item = anexoViviendaMadrid.value[1]
      const { dataUsers, piso, letra } = item
      anexoMadVivienda(dataUsers, piso, letra)
    }

    return {
      expedienteSelect,
      usersInmueblesSelect,
      inquilinos,
      catastro,
      catastroUsers,
      inmuebles,
      users,
      usuariosInmuebles,
      anexoViviendaMadrid,
      anexoEdificioMadrid,
      imprimirMadEdificio,
      imprimirMadVivienda,
      imprimirMadViviendaTest,
    }
  },
}
</script>

<style>

</style>
