export const extraeImagen = payload => new Promise(resolve => {
  const { dimension, data } = payload
  const { w, h } = dimension
  const canvas2 = document.createElement('canvas')
  canvas2.setAttribute('width', w)
  canvas2.setAttribute('height', h)
  const ctx = canvas2.getContext('2d')
  const imageObject = new Image()
  imageObject.src = data

  imageObject.onload = () => {
    ctx.drawImage(imageObject, dimension.xInit, dimension.yInit, dimension.w, dimension.h, 0, 0, w, h)
    const d = canvas2.toDataURL()
    resolve(d)
  }
})

const dniSelection = (dni = false) => {
  const coolors = [
    [52, 57, 159],
    [25, 60, 78],
    [32, 24, 24],
    [24, 26, 41],
    [79, 72, 146],
    [21, 30, 49],
    [78, 71, 163],
    [31, 37, 60],
    [34, 29, 29],
    [31, 58, 87],
  ]

  const dimensions = [75, 80, 85, 90, 95, 100, 105, 110, 115, 120] // Porcentaje sobre el tamaño real

  const result = {
    position: 0,
    colors: coolors[0],
    dimension: dimensions[5],
    dimensionIndex: 5,
    positionIndex: 0,
    coloredIndex: 0,
  }

  if (!dni || dni.length < 3) {
    console.log('DNI inválido')
    return result
  }
  const dniArray = dni.split('')
  const coloredIndex = parseInt(dniArray[dniArray.length - 2], 10) || 0
  const positionIndex = parseInt(dniArray[dniArray.length - 3], 10) || 0
  const dimensionIndex = parseInt(dniArray[dniArray.length - 4], 10) || 0
  result.position = positionIndex
  result.positionIndex = positionIndex
  result.dimension = dimensions[dimensionIndex]
  result.dimensionIndex = dimensionIndex
  result.colors = coolors[coloredIndex]
  result.coloredIndex = coloredIndex
  return result
}

/**
 * generaFirma(firma, dni)
 * @param {firma, dni} dataLocales
 */
export const generaFirma = async (img, dni) => {
  const canvas = document.createElement('canvas')
  canvas.setAttribute('width', 450)
  canvas.setAttribute('height', 200)

  const context = canvas.getContext('2d')
  let imageObj

  function getImgData() {
    return context.getImageData(0, 0, canvas.width, canvas.height)
  }

  function imgProcesa() {
    const proporciones = dniSelection(dni)
    const imageData = getImgData()
    const pixels = imageData.data
    const numPixels = imageData.width * imageData.height
    let initPixel = -1
    let endPixel = 0
    let wMin = imageData.width
    let wMax = 0
    const dimension = {
      xInit: 0,
      xEnd: 0,
      yInit: 0,
      yEnd: 0,
      w: 0,
      h: 0,
      canvasX: 450,
      canvasY: 200,
      proporciones,
    }
    for (let i = 0; i < numPixels; i += 1) {
      const r = pixels[i * 4]
      const g = pixels[i * 4 + 1]
      const b = pixels[i * 4 + 2]

      // const grey = (r + g + b) / 3
      if (r !== 0 || g !== 0 || b !== 0) {
        endPixel = i // Final en Y
        const yTemp = Math.floor(i / imageData.width)
        const xTemp = i - (imageData.width * yTemp)
        // busca el inicio en Y;
        if (initPixel < 0) {
          initPixel = i * 4
          dimension.yInit = yTemp
        }

        if (xTemp > wMax) {
          wMax = xTemp
        }
        if (xTemp < wMin) {
          wMin = xTemp
        }
        /* eslint-disable prefer-destructuring */
        pixels[i * 4] = proporciones.colors[0]
        pixels[i * 4 + 1] = proporciones.colors[1]
        pixels[i * 4 + 2] = proporciones.colors[2]
        /* eslint-enable prefer-destructuring */
      }
    }
    dimension.yEnd = Math.ceil(endPixel / imageData.width)
    dimension.h = dimension.yEnd - dimension.yInit
    dimension.xInit = wMin
    dimension.xEnd = wMax + 1
    dimension.w = dimension.xEnd - dimension.xInit
    dimension.canvasX = (proporciones.dimension / 100) * 450
    dimension.canvasY = (proporciones.dimension / 100) * 200
    context.putImageData(imageData, 0, 0)
    const payload = {
      data: canvas.toDataURL(),
      dimension,
    }
    return payload
  }

  function loadPicture() {
    return new Promise(resolve => {
      imageObj = new Image()
      imageObj.src = img
      imageObj.onload = () => {
        context.drawImage(imageObj, 0, 0)
        resolve(imgProcesa())
      }
    })
  }

  const result = await loadPicture()
  return result
}
